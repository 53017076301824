import React, { useState } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const IndexPage = () => {
  const { t } = useTranslation();
  const [btnText, setBtnText] = useState(t("Submit"));
  const [showSuccess, setShowSuccess] = useState(false);
  function formSubmit(e) {
    e.preventDefault();
    let timeleft = 1;
    let timer = setInterval(function () {
      if (timeleft > 3) {
        setBtnText(t("Submit"));
        setShowSuccess(true);
        clearInterval(timer);
      }
      else {
        let dots = '';
        for (let i = 1; i <= timeleft; i++) {
          dots = dots + '.';
        }
        setBtnText(t("Submitting") + dots);
        timeleft += 1;
      }
    }, 1000);
  }
  return (
    <Layout>
      <Seo title={t("Contact Us")} description={t("Do you have any questions? Please do not hesitate to contact us directly by filling below form. Our team will come back to you shortly to help you.")} />
      <Row>
        <Col className="text-center download-section pt-4 pb-3">
          <h1>{t("Contact us")}</h1>
          <p>{t("Do you have any questions? Please do not hesitate to contact us directly by filling below form. Our team will come back to you shortly to help you.")}</p>
        </Col>
      </Row>
      <Container className="py-4">
        <Row>
          <Col className="col-md-9">
            <form onSubmit={(e) => formSubmit(e)}>
              <div className="mb-3">
                <label className="form-label">{t("Name")}</label>
                <input className="form-control" type="" placeholder={t("Please enter your name")} required />
              </div>
              <div className="mb-3">
                <label className="form-label">{t("Email Address")}</label>
                <input className="form-control" type="email" placeholder={t("Please enter your email address")} required />
              </div>
              <div className="mb-3">
                <label className="form-label">{t("Message")}</label>
                <textarea className="form-control" type="text" placeholder={t("Please type your message")} style={{ height: "10rem" }} required></textarea>
              </div>
              <div className="d-grid">
                <button className="btn btn-danger btn-lg" type="submit">{btnText}</button>
              </div>
            </form>
            {showSuccess && (
              <div className="alert alert-success mt-3" role="alert">
                {t("Success! We will get back to you soon.")}
              </div>
            )}
          </Col>
          <Col className="col-md-3 text-center pt-md-5">
            <ul className="list-unstyled mb-0 mt-5">
              <li className="mb-4">
                <h4>{t("Address")}</h4>
                <p>Sims Avenue, 387515, Singapore</p>
              </li>
              <li>
                <h4>{t("Our Email")}</h4>
                <p>contact@snapzy.net</p>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
